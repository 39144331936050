<template>
  <b-modal
    id="show-termo"
    no-close-on-esc
    no-close-on-backdrop
    hide-header
    hide-footer
    centered
    v-model="showModal"
  >
    <div
      class="d-flex flex-column align-items-center justify-center-center my-2"
    >
      <div class="term-content" v-if="!attentionIcon">
        <feather-icon icon="LockIcon" class="icon-termo" size="32" />
      </div>
      <div v-else>
        <feather-icon icon="AlertCircleIcon" class="icon-termo" size="70" />
      </div>
      <h2 v-if="!attentionIcon" class="text-center mt-3">Consentimento para uso de dados</h2>
      <h2 v-else class="text-center mt-1">Atenção!</h2>

      <div
        class="d-flex flex-column align-items-center justify-center-center font-weight-bold"
      >
        <p>
          <span v-html="messageTermo"></span>

         <br/> Para mais informações, consulte nossa
          <b-link :to="{ name: 'politica-privacidade' }" target="_blank" class="text-custom-blue">
            Política de Privacidade
          </b-link>
          ou nossa seção de
          <b-link href="/ajuda" target="_blank" class="text-custom-blue"
            >Ajuda e Suporte.</b-link
          >
        </p>

        <b-form-checkbox
          id="checkbox-term"
          v-model="status"
          name="checkbox-term"
          value="accept"
          unchecked-value="not_accept"
          class="mt-2"
        >
          Informo que li e estou ciente do tratamento e uso dos dados.
        </b-form-checkbox>
      </div>

      <div class="d-flex mt-1">
        <b-button
          :disabled="status == 'not_accept'"
          variant="primary-button"
          class="mr-2"
          @click="confirmAction"
        >
          Continuar
        </b-button>

        <b-button
          variant="outline-secondary"
          class="mr-2"
          @click="redirectToPageCompany"
        >
          Cancelar
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BButton, BLink, BFormCheckbox } from "bootstrap-vue";
import platform from "platform";
import VerificaAceiteTermoEmpresa from '@/views/custom-pages/colaborador/Services/VerificaAceiteTermoEmpresa';

export default {
  name: "ConfirmaTermoModal",
  components: {
    BModal,
    BButton,
    BLink,
    BFormCheckbox,
  },
  data() {
    return {
      status: 'not_accept',
      messageTermo: "",
      attentionIcon: false,
      userData: this.$store.state.sessions.userData,
    };
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    IdEmpresa: {
      type: Number,
      default: 0,
    }
  },

  mounted() {
    const profiles = this.userData.perfis.map((profile) => profile.id_perfil);

    if(VerificaAceiteTermoEmpresa.usuarioSESI(profiles)) {
      this.attentionIcon = true;
      this.messageTermo = `
        Esta empresa ainda não manifestou seu consentimento para uso de dados. 
        Para prosseguir com o cadastro ou importação de colaboradores, 
        será necessário obter este consentimento. Se você, representando o SESI, 
        possui autorização da mesma para seguir com o cadastro ou importação dos colaboradores, 
        poderá dar o aceite como SESI clicando na caixa de seleção abaixo.</br></br>
        
        Este aceite não impede que a empresa também manifeste o consentimento acessando o VacinaSESI futuramente.</br></br>

        Reforça-se que o SESI garante o tratamento destas informações observando os cuidados de segurança e confidencialidade 
        estabelecidos na <a href="https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm" target="_blank">Lei nº 13.709 do Marco Civil da Internet</a>.</br>
      `;
      return;
    }

    this.$http.get(this.$api.getMessageTermsCompany()).then((response) => {
      this.messageTermo = response.data;
    });
  },

  methods: {
    confirmAction(modalEvent) {
      modalEvent.preventDefault();
      this.$emit("confirmed");
      const parameters = {
        id_empresa: this.IdEmpresa,
        dispositivo: platform.product ?? platform.name,
        fabricante: platform.manufacturer ?? "",
        so: platform.os.family,
        versao_so: platform.os.version ?? "",
      };
      if (this.status == 'accept') {
        this.$http
          .post(this.$api.acceptTermCompany(), parameters)
          .catch(({ response: { data } }) => {

            this.$emit("errorModal", data);
          });
      } else {
        this.redirectToPageCompany();
      }
      this.status = 'not_accept'
    },

    redirectToPageCompany() {
      this.$router.push({ name: "empresa-show" });
    },
  },
};
</script>

<style scoped lang="scss">
p {
  font-size: 14px;
  text-align: justify;
  line-height: 21px;
}
.term-content {
  border: 4px solid #ff9110;
  border-radius: 100%;
  padding: 1rem;
  text-align: center;
}
.icon-termo {
  color: #ff9110;
  font-weight: 800;
  padding: 0;
}
</style>
