import perfis from '@/regras/perfis.js';

const MENSAGEM_NINGUEM_ACEITOU_TERMO = `
    Até o momento, esta empresa não manifestou o consentimento para uso dos dados acessando o VacinaSESI.</br></br>
    O SESI, até este momento, não foi autorizado pela empresa a realizar o cadastro de colaboradores.</br></br>
    Se você é responsável por esta empresa, 
        <a 
            class="font-weight-bold text-custom-blue"
            style="text-decoration: underline;"  
            href="{{ rotaLinkAceiteTermo }}"
        >clique aqui</a> 
     para dar o consentimento.`;

const MENSAGEM_SESI_ACEITOU_ADMIN_EMPRESA_NAO = `
    Até o momento, esta empresa não manifestou o consentimento para uso dos dados acessando o VacinaSESI, 
    porém, o SESI foi autorizado pela mesma a realizar esta ação e cadastrar os colaboradores.</br></br> 
    O usuário SESI responsável por registrar essa autorização foi 
    <b>{{ nome_usuario }}</b> em {{ data_aceite }} às {{ hora_aceite }}.`;

const MENSAGEM_ADMIN_EMPRESA_ACEITOU_SESI_NAO = `
    Esta empresa manifestou seu consentimento com o uso de dados acessando o VacinaSESI, 
    isentando o usuário SESI de registrar qualquer autorização para essa finalidade.</br></br> 
    Esta empresa manifestou sua concordância com estes termos em
    {{ data_aceite }} às {{ hora_aceite }} por meio do usuário <b>{{ nome_usuario }}</b>.`;

const MENSAGEM_DOIS_ACEITES_TERMO_EMRPESA = `
    Esta empresa autorizou inicialmente o SESI a realizar o cadastro dos colaboradores e, 
    posteriormente, manifestou seu consentimento com o uso de dados acessando o VacinaSESI.</br></br>
    O usuário SESI responsável por registrar essa autorização foi 
    <b>{{ nome_usuario_sesi }}</b> em {{ data_aceite_sesi }} às {{ hora_aceite_sesi }}.</br></br>
    Esta empresa manifestou sua concordância com estes termos em 
    {{ data_aceite_empresa }} às {{ hora_aceite_empresa }} por meio do usuário <b>{{ nome_usuario_empresa }}</b>.`;

export default {
    termoAceitoAdminEmpresa(termoEmpresa) {
        if(termoEmpresa.length >= 1) {  
            const aceites = termoEmpresa.map(empresaTermo => empresaTermo.id_perfil);

            return aceites.includes(perfis.ADMIN_EMPRESA)
        }

        return false;
    },

    termoJaAceitoPeloSESI(termoEmpresa) {
        if(termoEmpresa.length >= 1) {  
            const aceites = termoEmpresa.map(empresaTermo => empresaTermo.id_perfil);

            return  aceites.includes(perfis.SAUDE_DR) ||
                    aceites.includes(perfis.SAUDE_UO) ||
                    aceites.includes(perfis.SAUDE_REGIAO);
        }

        return false;
    },

    usuarioSESI(perfisUsuarioLogado) {
        if(
            perfisUsuarioLogado.includes(perfis.SAUDE_DR) ||
            perfisUsuarioLogado.includes(perfis.SAUDE_UO) ||
            perfisUsuarioLogado.includes(perfis.SAUDE_REGIAO)
        ) {
            return true;
        }

        return false;
    },

    verificaSeTermoPrecisaSerAceito(termoEmpresa, perfisUsuarioLogado) {
        if(this.termoJaAceitoPeloSESI(termoEmpresa) && this.usuarioSESI(perfisUsuarioLogado)) {
            return false;
        }

        if(this.termoAceitoAdminEmpresa(termoEmpresa)) {
            return false;
        }

        return true;
    },

    mensagemExibicaoTermoEmpresa(dadosTermo) {
        if(dadosTermo.length === 0) {
            const linkAceiteTermo = process.env.VUE_APP_APP_URL + '/importar-colaboradores';
            return MENSAGEM_NINGUEM_ACEITOU_TERMO.replace('{{ rotaLinkAceiteTermo }}', linkAceiteTermo);
        }

        if(dadosTermo.length === 1) {
            if(this.termoJaAceitoPeloSESI(dadosTermo) && !this.termoAceitoAdminEmpresa(dadosTermo)) {
                return this.dadosAceiteMensagem(MENSAGEM_SESI_ACEITOU_ADMIN_EMPRESA_NAO, dadosTermo);
            } else {
                return this.dadosAceiteMensagem(MENSAGEM_ADMIN_EMPRESA_ACEITOU_SESI_NAO, dadosTermo);
            }
        }   

        if(dadosTermo.length === 2) {
            return this.dadosAceiteMensagem(MENSAGEM_DOIS_ACEITES_TERMO_EMRPESA, dadosTermo);
        }
    },

    dadosAceiteMensagem(mensagem, dadosTermo) {
        mensagem = mensagem.replace('{{ data_aceite }}', dadosTermo[0].data_aceite);
        mensagem = mensagem.replace('{{ hora_aceite }}', dadosTermo[0].hora_aceite);
        mensagem = mensagem.replace('{{ nome_usuario }}', dadosTermo[0].usuario);

        mensagem = mensagem.replace('{{ data_aceite_sesi }}', dadosTermo[0].data_aceite);
        mensagem = mensagem.replace('{{ hora_aceite_sesi }}', dadosTermo[0].hora_aceite);
        mensagem = mensagem.replace('{{ nome_usuario_sesi }}', dadosTermo[0].usuario);

        if(dadosTermo[1]) {
            mensagem = mensagem.replace('{{ data_aceite_empresa }}', dadosTermo[1].data_aceite);
            mensagem = mensagem.replace('{{ hora_aceite_empresa }}', dadosTermo[1].hora_aceite);
            mensagem = mensagem.replace('{{ nome_usuario_empresa }}', dadosTermo[1].usuario);
        }
        
        return mensagem;
    }
}